function setEqualHeight() {
    if (skel.vars.stateId.search("/medium") < 0) {
        $(".facebook-wrapper").matchHeight({
            target: $(".upcoming-events")
        });
    }
    $('.knjizni-klub .equal').matchHeight();
    $('.grid-product .grid-element-title').matchHeight();
    $('.grid-product .product-options').matchHeight();
    $('.grid-product').matchHeight();
}

$(window).on("load",function() {
    setEqualHeight();
});
$(window).resize(function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$(window).bind('scroll', function() {
    var scroll = 109,
        skelBreakpoints = skel.vars.stateId;
    if (skelBreakpoints.search("/large") > -1) {
        scroll = 89;
    }
    if ($(window).scrollTop() > scroll) {
        $('body').addClass('fixed');
    } else {
        $('body').removeClass('fixed');
    }
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});
